export const AUTHORS = {
  benWood: {
    name: 'Benjamin Wood',
    bio:
      "Ben is a family man and partner at <a href='https://hint.io'>Hint</a>. When " +
      "not shipping software with the team at Hint, you'll likely find him spending " +
      'time with his wife and two children.',
    imageKey: 'benWood',
    social: {
      twitter: 'benjaminwood',
      github: 'benjaminwood',
      linkedIn: null
    }
  },
  jasonDinsmore: {
    name: 'Jason Dinsmore',
    bio:
      "Jason is a software engineer at <a href='https://hint.io'>Hint</a>. He loves " +
      "crafting great software, improving his fitness, and chillin' with any of his 5 dogs.",
    imageKey: 'jasonDinsmore',
    social: {
      twitter: 'dinjas',
      github: 'dinjas',
      linkedIn: null
    }
  },
  joshWood: {
    name: 'Josh Wood',
    bio:
      "Josh is the founder of <a href='https://hint.io' target='_blank'>Hint</a> and cofounder of " +
      "<a href='https://honeybadger.io' target='_blank'>Honeybadger.io</a>. He's interested in a thousand different " +
      'things, not the least of which are software engineering and product development.',
    imageKey: 'joshWood',
    social: {
      github: 'joshuap',
      linkedIn: null
    }
  },
  kyleBoe: {
    name: 'Kyle Boe',
    bio:
      "Kyle is a member of the development team at <a href='https://hint.io'>Hint</a>. In his " +
      'free time, Kyle enjoys throwing LAN parties and contributing to open source software.',
    imageKey: 'kyleBoe',
    social: {
      twitter: 'kbiz',
      github: 'kyleboe',
      linkedIn: 'kyleboe'
    }
  },
  nateShoemaker: {
    name: 'Nate Shoemaker',
    bio:
      'Nate is our resident JavaScript nerd. He loves learning about and ' +
      'exploring the latest and greatest front-end technologies. Outside of ' +
      'work, he can be found spending time with his wife, studying Elixir, and looking for any excuse to buy music gear.',
    imageKey: 'nateShoemaker',
    social: {
      twitter: null,
      github: null,
      linkedIn: null
    }
  },
  nateVick: {
    name: 'Nate Vick',
    bio:
      "Nate is partner and COO at <a href='https://hint.io'>Hint</a>. He keeps " +
      'the wheels turning, so to speak. In his free time he enjoys spending ' +
      'time with his wife and kids, hiking, and exploring new technology.',
    imageKey: 'nateVick',
    social: {
      twitter: 'natron99',
      github: 'nvick',
      linkedIn: 'natevick'
    }
  },
  joelHayhurst: {
    name: 'Joel Hayhurst',
    bio:
      'Joel has been programming in Rails since before version 1.0. ' +
      'He spends his free time with nature, family, and music.',
    imageKey: 'joelHayhurst',
    social: {
      github: 'joelmichael',
      linkedIn: 'joelhayhurst'
    }
  },
  timChambers: {
    name: 'Tim Chambers',
    bio:
      'Tim has been developing code that empowers people for a very, very long time. ' +
      'When he is not developing, he and his wife rescue senior dogs and provide them forever homes.',
    imageKey: 'timChambers',
    social: {
      github: 'tjchambers',
      linkedIn: 'tjchambers'
    }
  }
}
