import React from 'react'
import injectSheet from 'react-jss'
import { COLORS } from 'helpers/globals'
import style from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BlogShare = ({ path, classes }) => (
  <div className={classes.container}>
    <hr />
    <a
      href={`https://twitter.com/intent/tweet?text=https%3A//hint.io${path}`}
      target='_blank'
      className={classes.shareLink}
    >
      <FontAwesomeIcon
        size='lg'
        className={classes.twitterIcon}
        icon={['fab', 'twitter']}
        color={COLORS.red}
      />
      &nbsp;
      <span>SHARE THIS POST!</span>
    </a>
    <hr />
  </div>
)

export default injectSheet(style)(BlogShare)
