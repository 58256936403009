import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import AuthorBio from 'components/AuthorBio'
import { AUTHORS } from 'helpers/authors'
import BlogHeader from 'components/BlogHeader'
import BlogShare from 'components/BlogShare'
import CallToActionBlock from 'components/CallToActionBlock'
import injectSheet from 'react-jss'
import style from './styles'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import { Helmet } from 'react-helmet'

class BlogTemplate extends React.Component {
  render() {
    const {
      data: {
        site: {
          siteMetadata: { siteUrl }
        },
        mdx: {
          frontmatter: {
            author,
            displayAuthorHeader,
            path,
            date,
            seo,
            title,
            summary,
            image
          },
          body
        },
        allMdx: { edges }
      },
      classes
    } = this.props
    const authorInfo = AUTHORS[author]

    return (
      <Layout>
        <Helmet
          meta={[
            {
              property: 'og:url',
              content: `${siteUrl}${path}`
            },
            {
              property: 'og:type',
              content: seo.og.type
            },
            {
              property: 'og:title',
              content: title
            },
            {
              property: 'og:description',
              content: summary
            },
            {
              property: 'og:image',
              content: `${siteUrl}${image.childImageSharp.resolutions.src}`
            },
            {
              property: 'twitter:card',
              content: seo.twitter.card
            },
            {
              property: 'twitter:creator',
              content: seo.twitter.creator
            }
          ]}
        />
        <BlogHeader />
        <div className='container'>
          <div className={classes.blogPostContainer}>
            <div className={classes.blogPost}>
              <h1>{title}</h1>
              {displayAuthorHeader !== false && (
                <div className={classes.authorName}>
                  {authorInfo.name} - {date}
                </div>
              )}

              <div className={classes.blogPostContent}>
                <MDXRenderer>{body}</MDXRenderer>
              </div>
              <BlogShare path={path} />
              <div className={classes.furtherReading}>
                <h3 className={classes.furtherReadingHeading}>
                  Further Reading/Listening
                </h3>
                <ul className={classes.furtherReadingList}>
                  {edges.map(({ node: { frontmatter: { path, title } } }, i) => (
                    <li key={i}>
                      <a href={path} target='_blank'>
                        {title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <AuthorBio author={authorInfo} />
        <CallToActionBlock
          title='Ready to Get Started?'
          linkText="LET'S CONNECT"
          linkHref='/contact'
        />
      </Layout>
    )
  }
}
export default injectSheet(style)(BlogTemplate)

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        author
        displayAuthorHeader
        date(formatString: "MMMM DD, YYYY")
        path
        summary
        title
        image {
          childImageSharp {
            resolutions(width: 600) {
              src
            }
          }
        }
        seo {
          og {
            type
          }
          twitter {
            card
            creator
          }
        }
      }
    }
    allMdx(
      filter: { frontmatter: { path: { ne: $path } }, fileAbsolutePath: {regex: "/blog/"} }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 4
    ) {
      edges {
        node {
          frontmatter {
            path
            title
          }
        }
      }
    }
  }
`
