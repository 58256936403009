import React from 'react'
import injectSheet from 'react-jss'
import { AUTHORIMAGES } from './authorImages'
import SocialLink from './SocialLink'
import style from './styles'

class AuthorBio extends React.Component {
  render() {
    const { author, classes } = this.props
    const { bio, imageKey, name, social } = author
    let twitter, github, linkedIn
    if (social['twitter']) {
      twitter = (
        <SocialLink path='twitter.com' handle={social['twitter']} type='twitter' />
      )
    }
    if (social['github']) {
      github = <SocialLink path='github.com' handle={social['github']} type='github' />
    }
    if (social['linkedIn']) {
      linkedIn = (
        <SocialLink path='linkedin.com/in/' handle={social['linkedIn']} type='linkedIn' />
      )
    }

    return (
      <div className={classes.authorContainer}>
        <img className={classes.authorImage} src={AUTHORIMAGES[imageKey]} />
        <h2 className={classes.authorName}>{name}</h2>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-8 offset-2'>
              <p
                className={classes.authorBody}
                dangerouslySetInnerHTML={{ __html: bio }}
              />
              {twitter}
              {github}
              {linkedIn}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectSheet(style)(AuthorBio)
