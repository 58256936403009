import { COLORS, SPACING } from 'helpers/globals'

export default {
  container: {
    display: 'block',
    margin: `${SPACING.base} 0`
  },
  '& hr': {
    color: COLORS.lightestGrey
  },
  shareLink: {
    color: COLORS.red,
    display: 'inline-flex',
    '& span': {
      alignItems: 'center',
      margin: `${SPACING.tiny} 0`,
      display: 'inline-flex',
      fontWeight: 600,
      lineHeight: '0.75em' // To match font awesome 'lg' line height
    }
  },
  twitterIcon: {
    margin: `${SPACING.tiny} 0 ${SPACING.tiny} ${SPACING.tiny}`
  }
}
