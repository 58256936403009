import benWoodImage from 'assets/images/team/ben-blog.jpg'
import jasonDinsmoreImage from 'assets/images/team/jason-blog.jpg'
import joshWoodImage from 'assets/images/team/josh-blog.jpg'
import kyleBoeImage from 'assets/images/team/kyle-blog.jpg'
import nateShoemakerImage from 'assets/images/team/shoe-blog.jpg'
import nateVickImage from 'assets/images/team/natevick-blog.jpg'
import joelHayhurst from 'assets/images/team/joel-blog.jpg'
import timChambers from 'assets/images/team/ac7da6355037790919410259aaf19865.png'

export const AUTHORIMAGES = {
  benWood: benWoodImage,
  jasonDinsmore: jasonDinsmoreImage,
  joshWood: joshWoodImage,
  kyleBoe: kyleBoeImage,
  nateShoemaker: nateShoemakerImage,
  nateVick: nateVickImage,
  timChambers,
  joelHayhurst
}
