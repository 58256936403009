import { COLORS, FONT_SIZES, SPACING } from 'helpers/globals'
import { styleUpToSm, styleFromLg } from 'helpers/screens'

export default {
  authorContainer: {
    marginTop: SPACING.xxl,
    backgroundColor: COLORS.lightestGrey,
    paddingBottom: SPACING.xl,
    ...styleUpToSm({
      paddingBottom: SPACING.large
    }),
    position: 'relative',
    textAlign: 'center'
  },
  authorBody: {
    fontSize: FONT_SIZES.regular,
    marginBottom: SPACING.large,
    '& a': {
      color: COLORS.red
    },
    ...styleFromLg({
      fontSize: FONT_SIZES.large,
      lineHeight: SPACING.large
    })
  },
  authorImage: {
    height: 'auto',
    border: `3px solid ${COLORS.white}`,
    borderRadius: '50%',
    marginTop: `-${SPACING.xxl}`,
    maxWidth: '8rem'
  },
  authorName: {
    fontSize: FONT_SIZES.large,
    ...styleFromLg({
      fontSize: FONT_SIZES.xl
    })
  }
}
