import { COLORS, SPACING, FONT_SIZES } from 'helpers/globals'
import quotes from 'assets/svgs/quotes.svg'
import slashes from 'assets/images/slashes.svg'
import vSlashes from 'assets/svgs/slashesLarge.svg'
import { styleFromSm, styleFromLg, styleFromXl } from 'helpers/screens'

export default {
  authorName: {
    fontWeight: 700,
    color: COLORS.grey,
    marginBottom: SPACING.base
  },
  blogPost: {
    wordBreak: 'break-word'
  },
  blogPostContainer: {
    backgroundColor: COLORS.white,
    marginBottom: SPACING.xxxl,
    maxWidth: '700px',
    margin: '0 auto'
  },
  blogPostContent: {
    wordBreak: 'break-word',
    '& a': {
      color: COLORS.red
    },
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      marginLeft: SPACING.small,
      maxWidth: '90%',
      marginBottom: SPACING.small,
      '& li': {
        marginBottom: SPACING.teeny,
        paddingLeft: SPACING.small,
        textIndent: `-${SPACING.base}`
      },
      '& li::before': {
        color: COLORS.red,
        content: '"•"',
        paddingRight: SPACING.small
      }
    },
    '& audio': {
      width: '100%'
    },
    '& img': {
      width: '100%'
    },
    '& pre': {
      overflowX: 'auto',
      padding: SPACING.small,
      backgroundColor: COLORS.darkGrey
    },
    '& code:not([class^="language-"])': {
      backgroundColor: '#f8f8f8',
      padding: `${SPACING.teeny} .25rem`,
      border: '1px solid #d5d5d5',
      borderRadius: 3,
      fontSize: '0.9rem'
    },
    '& hr': {
      margin: '3rem 0'
    },
    '& table': {
      wordBreak: 'initial',
      overflow: 'auto',
      display: 'block'
    },
    '& th, td': {
      padding: `${SPACING.tiny} ${SPACING.small}`
    },
    '& h1, h2, h3, h4, h5': {
      margin: '1.5rem 0 .5rem 0'
    },
    '& blockquote': {
      backgroundColor: COLORS.darkGrey,
      backgroundImage: `url(${slashes})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: `right -${SPACING.xxl} bottom -${SPACING.xxxl}`,
      color: COLORS.white,
      fontSize: FONT_SIZES.large,
      fontWeight: 'bold',
      borderLeft: `3px solid ${COLORS.red}`,
      paddingTop: SPACING.base,
      paddingBottom: SPACING.base,
      paddingRight: SPACING.base,
      paddingLeft: SPACING.xl,
      position: 'relative',
      lineHeight: SPACING.large,
      '&:before': {
        backgroundColor: COLORS.red,
        content: `url(${quotes})`,
        position: 'absolute',
        left: -14,
        top: SPACING.base,
        paddingTop: 10,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5
      },
      ...styleFromSm({
        '&:before': {
          left: -22,
          paddingTop: 10.5,
          paddingLeft: 9,
          paddingRight: 9,
          paddingBottom: 9.5
        }
      }),
      ...styleFromLg({
        paddingTop: SPACING.large,
        paddingBottom: SPACING.large,
        paddingRight: SPACING.large,
        paddingLeft: SPACING.xxl,
        '&:before': {
          top: SPACING.large
        }
      }),
      ...styleFromXl({
        paddingTop: SPACING.xl,
        paddingBottom: SPACING.xl,
        paddingRight: SPACING.xl,
        paddingLeft: SPACING.xxxl,
        '&:before': {
          top: SPACING.xl
        }
      }),
      '& byline': {
        color: COLORS.lightGrey,
        display: 'block',
        paddingTop: SPACING.small,
        fontStyle: 'italic'
      }
    },
    '& .callout': {
      '&:before': {
        position: 'absolute',
        top: -190,
        left: 0,
        content: `url(${vSlashes})`
      },
      '&:after': {
        position: 'absolute',
        bottom: -240,
        right: 0,
        content: `url(${vSlashes})`
      },
      marginBottom: SPACING.small,
      overflow: 'hidden',
      color: COLORS.darkGrey,
      fontSize: FONT_SIZES.large,
      fontWeight: 'bold',
      padding: SPACING.base,
      position: 'relative',
      lineHeight: SPACING.large,
      ...styleFromLg({
        padding: SPACING.large
      })
    }
  },
  furtherReading: {
    display: 'block'
  },
  furtherReadingHeading: {
    margin: 0
  },
  furtherReadingList: {
    color: COLORS.red,
    listStyle: 'none',
    lineHeight: SPACING.large,
    marginTop: SPACING.tiny,
    paddingLeft: 0,
    '& a': {
      color: COLORS.red
    }
  }
}
